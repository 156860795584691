<template>
  <form @submit.prevent="send">
    <input type="text" autofocus style="display: none">

    <div class="form__group">
      <label for="auth_login" class="form__label">Телефон или Email</label>
      <input
          type="text"
          class="form__input"
          id="auth_login"
          placeholder="7XXXXXXXXX или email@mail.ru"
          @change="validate"
          v-model="login"
      >
    </div>

    <div class="form__group">
      <label for="auth_password" class="form__label">Пароль</label>
      <input
          type="password"
          class="form__input"
          id="auth_password"
          placeholder="********"
          v-model="password"
      >
    </div>


    <div
        v-if="loginFeedBack !== null"
        class="auth__error">
      {{ loginFeedBack }}
    </div>

    <button
        class="button button--blue auth__button"
        :disabled="disabled"
        @click.prevent="send"
    >
      <div class="lds-ring"
           v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      Войти
    </button>

    <div class="d-flex" style="justify-content: space-between;">
      <a href="#" class="auth__link"
         @click.prevent="$emit('changeComponent', {component:'register'})"
      >Зарегистрироваться</a>
      <a href="#" class="auth__link"
         @click.prevent="$emit('changeComponent', {component:'forgot'})"
      >Забыли пароль</a>
    </div>
  </form>
</template>

<script>
import request from "../../request";

export default {
  name: "vue-login",
  watch: {
    login() {
      this.validate()
      this.disabled = !(this.loginValid === true && this.password.length >= 8);
    },
    password() {
      console.log(this.loginValid)
      this.disabled = !(this.loginValid === true && this.password.length >= 8);
    },
  },
  data() {
    return {
      loginValid: null,
      login: '',
      password: '',
      loginFeedBack: null,
      disabled: true,
      loading: false,
    }
  },
  methods: {
    async send() {
      this.loading = true
      this.disabled = true
      this.loginFeedBack = null

      const formdata = new FormData()
      formdata.append('login', this.login)
      formdata.append('password', this.password)
      const response = await request.post('/api/v1/auth/login', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false
        this.loginFeedBack = response.data.message
      }

      if (response.data.success === true) {
        location.reload()
      }
    },
    validate() {
      const validatedEmail = this.validateEmail()
      if (validatedEmail !== null) {
        this.loginValid = true
        return;
      }
      const validatedPhone = this.validatePhone()
      this.loginValid = validatedPhone !== null
    },
    validateEmail() {
      return String(this.login)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    validatePhone() {
      return String(this.login)
          .toLowerCase()
          .match(
              /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im
          )
    },
  },
}
</script>