<template>
  <div class="modal__content location__modal" id="modal-location-content">

    <div class="form__group">
      <input type="text" class="form__input" placeholder="Введите город"
         v-model="city"
         @input="dadataRequest"
      >
      <template v-if="suggestions.length > 0">
        <ul class="location__suggestions suggestions">
          <li v-for="item in suggestions"
            :key="item.key"
            @click="changeCity(item)"
            class="suggestions__item"
          >{{item.value}}</li>
        </ul>
      </template>
    </div>

    <ul class="location__list">
      <li v-for="item in cities"
          :key="item.city"
          class="location__item"
          :class="{
             'location__item--active': item.city === city
          }"
      >
        <a href="#" class="location__link"
           @click.prevent="changeCity(item)">
          <template v-if="item.main === 1"><strong>{{ item.city }}</strong></template>
          <template v-else>{{ item.city }}</template>
        </a>

        <span class="location__active">
          <template v-if="item.city === city"><i class="fas fa-check"></i></template>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import request from "../../request";

export default {
  name: "vue-ModalLocationSelect",
  components: {},
  created() {
    if (this.$parent.defaultCity !== undefined) {
      this.city = this.$parent.defaultCity
    }
  },
  data() {
    return {
      city: '',
      cities: [
        {city: 'Астана', main: 1, selected: 0},
        {city: 'Алматы', main: 1, selected: 0},
        {city: 'Шымкент', main: 1, selected: 0},
        {city: 'Актобе', main: 0, selected: 0},
        {city: 'Тараз', main: 0, selected: 0},
        {city: 'Павлодар', main: 0, selected: 0},
        {city: 'Атырау', main: 0, selected: 0},
        {city: 'Усть-Каменогорск', main: 0, selected: 0},
        {city: 'Уральск', main: 0, selected: 0},
        {city: 'Кызылорда', main: 0, selected: 0},
        {city: 'Костанай', main: 0, selected: 0},
        {city: 'Петропавловск', main: 0, selected: 0},
        {city: 'Актау', main: 0, selected: 0},
        {city: 'Туркестан', main: 0, selected: 0},
        {city: 'Кокшетау', main: 0, selected: 0},
        {city: 'Талдыкорган', main: 0, selected: 0},
      ],
      suggestions: []
    }
  },
  methods: {
    dadataRequest() {
      const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
      const token = "f950678d62ac32f2cb55c6bb271a181cb3d0a8e4";
      const query = this.city;
      if (this.city.length < 3) {
        return
      }
      this.suggestions = []
      const options = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
        },
        body: JSON.stringify({
          query: query,
          locations: [{country: 'Казахстан'}],
          from_bound: {"value": "city"},
          to_bound: {"value": "city"},
        })
      }

      fetch(url, options)
          .then(response => response.json())
          .then(result => {
            if (result.suggestions.length > 0) {
              this.suggestions = [];
              result.suggestions.forEach(item => {
                this.suggestions.push({
                  key: item.value,
                  value: item.value,
                  region: item.data.region_iso_code,
                  city: item.data.city,
                })
              })
            }
          })
          .catch(error => console.log("error", error));
    },
    changeCity(item) {
      this.city = item.city
      const response = new Promise((resolve) => {
        const formdata = new FormData()
        formdata.append('city', item.city)
        if (item.region !== undefined) {
          formdata.append('region', item.region)
        }
        request.post('/api/v1/location', formdata)
        resolve()
      })

      response.then(() => {
        setTimeout(() => {
          location.reload()
        }, 500)
      })
    },
  },
}
</script>
