<template>
  <div class="container">
    <form class="form profile" v-if="loaded">
      <div class="d-flex" style="margin-bottom: 20px;">
        <button
            class="register__type"
            type="button"
            :class="{'register__type--active': type === 'personal'}"
            @click="type = 'personal'"
        >Физ. лицо
        </button>
        <button
            class="register__type"
            type="button"
            :class="{'register__type--active': type === 'legal'}"
            @click="type = 'legal'"
        >Юр. лицо
        </button>
      </div>

      <div class="form__group">
        <label class="form__label">ФИО *</label>
        <input type="text" class="form__input" placeholder="ФИО"
               :class="{'error': errors.fullname !== ''}"
               v-model="fullname"
        >
        <p
            v-if="errors.fullname !== ''"
            class="alert alert--error"
        >
          {{ errors.fullname }}
        </p>
      </div>

      <div class="form__group">
        <label class="form__label">Телефон *</label>
        <input type="text" class="form__input" placeholder="7XXXXXXXXX"
               :class="{'error': errors.phone !== ''}"
               v-model="phone"
        >
        <p
            v-if="errors.phone !== ''"
            class="alert alert--error"
        >
          {{ errors.phone }}
        </p>
      </div>

      <div class="form__group">
        <label class="form__label">Email * </label>

        <div  class="d-flex"  v-if="emailVerified === 1">
          <input type="email" class="form__input form__email" placeholder="mail@mail.ru"
                 :class="{'error': errors.email !== ''}"
                 v-model="email"
                 @change="changeEmail"
          >

          <span class="alert alert--success profile__email-success">
            <i class="far fa-check-circle"></i>
          </span>
        </div>

        <template v-else>
          <input type="email" class="form__input" placeholder="mail@mail.ru"
                 :class="{'error': errors.email !== ''}"
                 v-model="email"
                 @change="changeEmail"
          >
        </template>


        <p
            v-if="errors.email !== ''"
            class="alert alert--error"
        >
          {{ errors.email }}
        </p>


        <div class="d-flex profile__email-verified"
           v-if="emailVerified === 0">
          <p class="alert alert--error">
            E-mail не подтвержден
          </p>

          <button class="button  button--blue profile__send-email"
                  :disabled="disabled"
                  @click="sendEmail"
          >
            <div class="lds-ring"
                 v-if="loadingEmail">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            Отправить письмо для подтверждения <i class="far fa-envelope"></i>
          </button>
        </div>



      </div>

      <div class="form__group">
        <label class="form__label">Страна</label>
        <input type="text" class="form__input" placeholder="Казахстан"
               :class="{'error': errors.country !== ''}"
               v-model="country"
        >
        <p
            v-if="errors.country !== ''"
            class="alert alert--error"
        >
          {{ errors.country }}
        </p>
      </div>

      <div class="form__group">
        <label class="form__label">Город</label>
        <input type="text" class="form__input" placeholder="Астана"
               :class="{'error': errors.city !== ''}"
               v-model="city"
        >
        <p
            v-if="errors.city !== ''"
            class="alert alert--error"
        >
          {{ errors.city }}
        </p>
      </div>

      <div class="form__group">
        <label class="form__label">Пароль</label>
        <input type="password" class="form__input" placeholder="********"
               :class="{'error': errors.password !== ''}"
               v-model="password"
               @change="changePasssword"
        >
        <p
            v-if="errors.password !== ''"
            class="alert alert--error"
        >
          {{ errors.password }}
        </p>
      </div>

      <div class="form__group">
        <label class="form__label">Подтвердите пароль</label>
        <input type="password" class="form__input" placeholder="********"
               :class="{'error': errors.repassword !== ''}"
               v-model="repassword"
               @change="changeRePasssword"
        >
        <p
            v-if="errors.repassword !== ''"
            class="alert alert--error"
        >
          {{ errors.repassword }}
        </p>
      </div>


      <template v-if="type === 'legal'">
        <div class="form__group">
          <label class="form__label">Компания</label>
          <input type="text" class="form__input" placeholder="Компания"
                v-model="company.company"
          >
        </div>

        <div class="form__group">
          <label class="form__label">БИН</label>
          <input type="text" class="form__input" placeholder="БИН"
                 v-model="company.bin"
          >
        </div>

        <div class="form__group">
          <label class="form__label">Юридический адрес (полный)</label>
          <input type="text" class="form__input" placeholder="Юридический адрес (полный)"
                 v-model="company.legal_address"
          >
        </div>

        <div class="form__group">
          <label class="form__label">БИК</label>
          <input type="text" class="form__input" placeholder="БИК"
                 v-model="company.bik"
          >
        </div>

        <div class="form__group">
          <label class="form__label">БАНК</label>
          <input type="text" class="form__input" placeholder="БАНК"
                 v-model="company.bank"
          >
        </div>

        <div class="form__group">
          <label class="form__label">ИИК (номер счета)</label>
          <input type="text" class="form__input" placeholder="ИИК (номер счета)"
                 v-model="company.iik"
          >
        </div>

        <div class="form__group">
          <label class="form__label">ФИО директора</label>
          <input type="text" class="form__input" placeholder="ФИО директора"
                 v-model="company.director"
          >
        </div>
      </template>

      <button class="button button--blue profile__button"
              :disabled="disabled"
              @click.prevent="send"
      >
        <div class="lds-ring"
             v-if="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        Сохранить
      </button>
    </form>
  </div>
</template>

<script>
import request from "../../request";

export default {
  name: "vue-Profile",
  data() {
    return {
      user_id: 0,
      disabled: false,
      loading: false,
      loadingEmail: false,
      loaded: false,
      fullname: '',
      email: '',
      phone: '',
      country: '',
      city: '',
      password: '',
      repassword: '',
      emailVerified: 0,
      type: '',
      company: {},
      errors: {
        fullname: '',
        email: '',
        phone: '',
        password: '',
        repassword: '',
        country: '',
        city: '',
      }
    }
  },
  created() {
    console.log(this.$parent.user_id)
    if (this.$parent.user_id !== undefined) {
      this.user_id = this.$parent.user_id
      this.get()
    }
  },
  watch: {
    password() {
      if (this.password.length >= 8) {
        this.errors.password = ''
      }
    },
    repassword() {
      if (this.repassword !== '' && this.repassword === this.password) {
        this.errors.repassword = ''
      }
    },
    email() {
      this.errors.email = ''
    },
  },
  methods: {
    async get() {
      console.log('/api/v1/profile/' + this.user_id)
      const response = await request.get('/api/v1/profile/' + this.user_id);
      console.log(response)
      if (response.data.success === true) {
        this.fullname = response.data.user.fullname
        this.email = response.data.user.email
        this.phone = response.data.user.mobilephone
        this.country = response.data.user.country
        this.city = response.data.user.city
        this.type = response.data.user.type
        if (response.data.company !== undefined) {
          this.company = response.data.company
        }
        this.emailVerified = response.data.user.email_verified
        this.loaded = true
      }
    },
    async send() {
      this.loading = true
      this.disabled = true
      this.errors = {
        fullname: '',
        email: '',
        phone: '',
        password: '',
        repassword: '',
        city: '',
        country: '',
      }

      const formdata = new FormData()
      formdata.append('fullname', this.fullname)
      formdata.append('email', this.email)
      formdata.append('phone', this.phone)
      formdata.append('password', this.password)
      formdata.append('city', this.city)
      formdata.append('country', this.country)
      formdata.append('type', this.type)
      if (this.type === 'legal') {
        formdata.append('company', JSON.stringify(this.company))
      }
      const response = await request.post('/api/v1/profile/' + this.user_id, formdata);

      if (response.data.success === false) {
        if (Object.keys(response.data.errors).length > 0) {
          Object.keys(response.data.errors).forEach(key => {
            this.errors[key] = response.data.errors[key]
          })
        }
      }

      if (response.data.success === true) {
        if (response.data.message) {
          window.miniShop2.Message.success(response.data.message)
        }
      }
      this.loading = false
      this.disabled = false
    },
    changePasssword() {
      if (this.password.length < 8) {
        this.errors.password = 'Длина пароля не менее 8 знаков'
      } else {
        this.errors.password = ''
      }
    },
    changeRePasssword() {
      if (this.repassword !== '' && this.repassword !== this.password) {
        this.errors.repassword = 'Пароль и его повтор должны совпадать'
      }
    },
    changeEmail() {
      if (this.validateEmail() === null) {
        this.errors.email = 'Email заполнен некорректно'
      }
    },
    validateEmail() {
      return String(this.email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    validatePhone() {
      return String(this.login)
          .toLowerCase()
          .match(
              /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im
          )
    },
    async sendEmail() {
      this.loadingEmail = true
      this.disabled = true

      const response = await request.post('/api/v1/profile/' + this.user_id + '/verified');

      if (response.data.success === false) {
        this.errors['email'] = response.data.errors['email']
      }

      if (response.data.success === true) {
        if (response.data.message) {
          window.miniShop2.Message.success(response.data.message)
        }
      }
      this.loadingEmail = false
      this.disabled = false
    },
  },
}
</script>

<style scoped>
  .form__email {
    width: calc(100% - 65px);
  }

  .profile__email-success {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 20px;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50px;
  }
</style>