<template>
  <div class="container orders">
    <ul class="orders__tabs">
      <li class="orders__tab"
          :class="{'orders__tab--active': activeTab === 0}"
      @click="setActiveTab(0)"
      >Заявки <span>{{ counts[0] }}</span></li>
      <li class="orders__tab"
          :class="{'orders__tab--active': activeTab === 1}"
          @click="setActiveTab(1)">
        Текущие
        <span>{{ counts[1] }}</span>
      </li>
      <li class="orders__tab"
          :class="{'orders__tab--active': activeTab === 2}"
          @click="setActiveTab(2)">История
        <span>{{ counts[2] }}</span>
      </li>
      <li class="orders__tab"
          :class="{'orders__tab--active': activeTab === 3}"
          @click="setActiveTab(3)">
        Отмененные
        <span>{{ counts[3] }}</span>
      </li>
    </ul>


    <ul class="orders__list" v-if="orders.length > 0">
      <li class="orders__header">
        <span>№</span>
        <span>Дата</span>
        <span>Сумма (тг)</span>
        <span>Статус</span>
      </li>

      <li class="orders__item"
        v-for="order in orders"
          :key="order.num"
      >
        <span class="orders__num">
          <span class="orders__mobile-title">№</span>
          <a :href="'/istoriya-zakazov/' + order.id">{{order.num}}</a>
        </span>
        <span class="orders__date">
          <span class="orders__mobile-title">Дата</span>
          {{ order.date }}&nbsp;&nbsp;&nbsp;{{ order.time }}</span>
        <span class="orders__cost">
          <span class="orders__mobile-title">Сумма (тг)</span>
          {{order.cost}} </span>
        <span class="orders__status">
          <span class="orders__mobile-title">Статус</span>

          <span class="orders__status-info">
            <span :class="'orders__status-name orders__status-' + order.status.id"
            >{{order.status.name}}</span>
           <span class="orders__status-date">{{ order.status_time }}</span>
          </span>
        </span>
      </li>
    </ul>

    <p v-else>У вас пока нет заказов</p>
  </div>
</template>

<script>
import request from "../../request";

export default {
  name: "vue-Orders",
  created() {
    if (this.$parent.user_id !== undefined) {
      this.user_id = this.$parent.user_id
      this.getCounts()
      this.getOrders(0)
    }
  },
  data() {
    return {
      user_id: 0,
      activeTab: 0,
      orders: [],
      counts: []
    }
  },
  methods: {
    async getCounts() {
      const response = await request.get('/api/v1/orders/' + this.user_id + '/count');
      if (response.data.success === true) {
        this.counts = response.data.counts
      }
    },
    setActiveTab(id) {
      this.activeTab = id
      this.getOrders(id)
    },
    async getOrders(id) {
      this.orders = []
      const formdata = new FormData()
      formdata.append('type_id', id)
      const response = await request.get('/api/v1/orders/' + this.user_id, formdata);
      if (response.data.success === true) {
        this.orders = response.data.orders
        console.log(response)
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>