<template>
  <div>
    <input type="text" autofocus style="display: none">

    <div class="d-flex" style="margin-bottom: 20px;">
      <button
          class="register__type"
          :class="{'register__type--active': type === 'personal'}"
          @click="type = 'personal'"
      >Физ. лицо
      </button>
      <button
          class="register__type"
          :class="{'register__type--active': type === 'legal'}"
          @click="type = 'legal'"
      >Юр. лицо
      </button>
    </div>

    <div class="form__group">
      <label for="auth_login" class="form__label">Телефон или Email</label>
      <input
          type="text"
          class="form__input"
          id="auth_login"
          placeholder="7XXXXXXXXX или email@mail.ru"
          @change="validate"
          v-model="login"
      >
    </div>

    <div class="form__group">
      <label for="auth_fullname" class="form__label">Имя</label>
      <input
          type="text"
          class="form__input"
          id="auth_fullname"
          placeholder="Имя"
          @change="validate"
          v-model="fullname"
      >
    </div>

    <div class="form__group" v-if="type === 'legal'">
      <label for="auth_company" class="form__label">Компания</label>
      <input
          type="text"
          class="form__input"
          id="auth_company"
          placeholder="Компания"
          @change="validate"
          v-model="company"
      >
    </div>

    <div class="form__group">
      <label for="auth_password" class="form__label">Пароль</label>
      <input type="password" class="form__input" id="auth_password" placeholder="********"
             v-model="password">
    </div>

    <div class="form__group">
      <label for="auth_repassword" class="form__label">Подтвердите пароль</label>
      <input type="password" class="form__input" id="auth_repassword" placeholder="********"
             v-model="repassword">
    </div>

    <div
        v-if="feedBack !== null"
        class="auth__error">
      {{ feedBack }}
    </div>

    <button
        class="button button--blue auth__button"
        :disabled="disabled"
        @click.prevent="send"
    >
      <div class="lds-ring"
           v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      Зарегистрироваться
    </button>

    <a href="#" class="auth__link"
       @click.prevent="$emit('changeComponent', {component:'login'})"
    >Уже зарегистрирован, войти</a>

  </div>
</template>

<script>
import request from "../../request";

export default {
  name: "vue-register",
  watch: {
    login() {
      this.validate()
      this.disabled = !(this.loginValid === true && this.password.length >= 8);
    },
    password() {
      if (this.password !== this.repassword) {
        this.feedBack = 'Введенные пароли не совпадают'
        this.disabled = true
        return
      } else {
        this.feedBack = null
      }
      this.disabled = !(this.loginValid === true && this.password.length >= 8);
    },
    repassword() {
      if (this.password !== this.repassword) {
        this.feedBack = 'Введенные пароли не совпадают'
        this.disabled = true
        return
      } else {
        this.feedBack = null
      }
      this.disabled = !(this.loginValid === true && this.password.length >= 8);
    },
  },
  data() {
    return {
      type: 'personal',
      login: '',
      password: '',
      repassword: '',
      fullname: '',
      company: '',
      loginValid: false,
      feedBack: null,
      disabled: true,
      loading: false
    }
  },
  methods: {
    validate() {
      const validatedEmail = this.validateEmail()
      if (validatedEmail !== null) {
        this.loginValid = true
        return;
      }
      const validatedPhone = this.validatePhone()
      this.loginValid = validatedPhone !== null
    },
    validateEmail() {
      return String(this.login)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    validatePhone() {
      return String(this.login)
          .toLowerCase()
          .match(
              /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im
          )
    },
    async send() {
      this.loading = true
      this.disabled = true
      this.feedBack = null

      const formdata = new FormData()
      formdata.append('login', this.login)
      formdata.append('password', this.password)
      formdata.append('fullname', this.fullname)
      formdata.append('type', this.type)
      formdata.append('company', this.company)
      const response = await request.post('/api/v1/auth/register', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false
        this.feedBack = response.data.message
      }

      if (response.data.success === true) {
        location.reload()
      }
    },
  }
}
</script>