<template>
  <section class="calc">
    <div class="container">
      <p class="calc__title section-title">Сделайте предварительный расчёт</p>

      <div class="calc__wrap calc__wrap--first">
        <div class="options calc__options">
          <div class="options__title">
            Тип системы
            <i class="fas fa-info-circle options__title-icon"
               @click="showTooltip('type')"
            ></i>
            <div class="options__tooltip tooltip"
              v-if="tooltip === 'type'"
            >
              <p>Современные системы видеонаблюдения технологически делятся на 2 типа:</p>
              <p><strong>AHD (CVI, TVI)</strong> – аналоговый сигнал высокой четкости – идеальное решение в соотношении
                цена-качество.</p>
              <p><strong>IP-камеры</strong> используют цифровой сигнал, который позволяет легко интегрировать
                видеонаблюдение с другими системами безопасности. Стоимость IP оборудования как правило в 2-3 раза больше
                AHD.</p>
              <i class="fas fa-times tooltip__close"
                 @click="closeTooltip()"
              ></i>
            </div>
          </div>
          <ul class="options__list">
            <li class="options__item">
              <input class="options__input-radio" type="radio" id="ahd"
                     :checked="type === 'ahd'"
                     @change="changeType('ahd')"
              >
              <label for="ahd">AHD</label>
            </li>
            <li class="options__item">
            <input class="options__input-radio" type="radio" id="ip"
                   :checked="type === 'ip'"
                   @change="changeType('ip')"
            >
            <label for="ip">IP</label>
          </li>
          </ul>
        </div>

        <div class="options calc__options">
          <div class="options__title">
            Качество изображения
            <i class="fas fa-info-circle options__title-icon"
               @click="showTooltip('quality')"
            ></i>
            <div class="options__tooltip tooltip"
                 v-if="tooltip === 'quality'"
            >
              <p>Качество камер видеонаблюдения выбирают в зависимости от задач, поставленных перед системой:</p>
              <p><strong>Хорошее (Full HD)</strong> - разрешение изображения 1920х1080 пикселей - такого качества вполне достаточно для получения обзорной картины помещения или улицы. На расстоянии 6-10 метров хорошо различимы номера автомобилей.</p>
              <p><strong>Отличное (Quad HD)</strong> - 2560х1440 пикселей. Одно из лучших решений для видеонаблюдения, где требуется высокая четкость и детализация изображения. </p>
              <p><strong>Лучшее (Ultra HD)</strong> - Великолепное изображение 3840х2160 пикселей, позволяет различать детальные черты лица, надписи, купюры.</p>
              <i class="fas fa-times tooltip__close"
                 @click="closeTooltip()"
              ></i>
            </div>
          </div>
          <ul class="options__list">
            <li class="options__item">
              <input class="options__input-radio" type="radio" id="fullhd"
                     :checked="quality === 'fullhd'"
                     @change="changeQuality('fullhd')"
              >
              <label for="fullhd">Хорошее (Full HD)</label>
            </li>
            <li class="options__item">
              <input class="options__input-radio" type="radio" id="quadhd"
                     :checked="quality === 'quadhd'"
                     @change="changeQuality('quadhd')"
              >
              <label for="quadhd">Отличное (Quad HD)</label>
            </li>
            <li class="options__item">
              <input class="options__input-radio" type="radio" id="ultrahd"
                     :checked="quality === 'ultrahd'"
                     @change="changeQuality('ultrahd')">
              <label for="ultrahd">Лучшее (Ultra HD)</label>
            </li>
          </ul>
        </div>
      </div>

      <div class="params calc__params">
        <div class="params__header">
          <div class="params__caption">Параметры системы</div>
          <div class="params__caption">Количество</div>
          <div class="params__caption">Цена</div>
        </div>

        <div class="params__wrap">
          <div class="params__ico">
            <img src="/assets/template/images/params/ico-1.svg" alt="">
          </div>
          <div class="params__title">
            <span>Наружные камеры</span>
            <div class="options params__options">
              <div class="options__item">
                <input class="options__input-checkbox custom-checkbox" type="checkbox" id="outside_focal"
                       :checked="outside.focal === true"
                       @change="changeFocal(outside, !outside.focal)"
                >
                <label for="outside_focal">Вариофокальный объектив</label>
              </div>
            </div>
          </div>
          <div class="params__count">
            <button class="params__count-btn"
                    @click="changeCount(outside, '-')"
            >-
            </button>
            <input type="number"
                   :value="outside.count"
                   @change="changeCount(outside, $event.target.value)"
            >
            <button class="params__count-btn"
                    @click="changeCount(outside, '+')"
            >+
            </button>
          </div>
          <div class="params__price">
            <template v-if="outside.cost > 0">
              ~ {{ outside.cost }} тг
            </template>
            <template v-else>0 тг</template>
          </div>
        </div>

        <div class="params__wrap">
          <div class="params__ico">
            <img src="/assets/template/images/params/ico-2.svg" alt="">
          </div>
          <div class="params__title">
            <span>Внутренние камеры</span>
            <div class="options params__options">
              <div class="options__item">
                <input class="options__input-checkbox custom-checkbox" type="checkbox" id="inside_focal"
                       :checked="inside.focal === true"
                       @change="changeFocal(inside, !inside.focal)"
                >
                <label for="inside_focal">Вариофокальный объектив</label>
              </div>
            </div>
          </div>
          <div class="params__count">
            <button class="params__count-btn"
                    @click="changeCount(inside, '-')"
            >-
            </button>
            <input type="number"
                   :value="inside.count"
                   @change="changeCount(inside, $event.target.value)"
            >
            <button class="params__count-btn"
                    @click="changeCount(inside, '+')"
            >+
            </button>
          </div>
          <div class="params__price">
            <template v-if="inside.cost > 0">
              ~ {{ inside.cost }} тг
            </template>
            <template v-else>0 тг</template>
          </div>
        </div>

        <div class="params__wrap">
          <div class="params__ico">
            <img src="/assets/template/images/params/ico-3.svg" alt="">
          </div>
          <div class="params__title">
            <span>Время хранения видео</span>
            <br>
            <small>Всего камер {{ totalCount }}</small>
          </div>

          <div class="calc__shelf">
            <div class="options params__options params__options--sub">
              <div class="options__item">
                <input class="options__input-radio" type="radio" id="time_7"
                       :checked="registrator.time === 'time_7'"
                       @change="changeRegistrator('time_7')"
                >
                <label for="time_7">7 дней</label>
              </div>
              <div class="options__item">
                <input class="options__input-radio" type="radio" id="time_14"
                       :checked="registrator.time === 'time_14'"
                       @change="changeRegistrator('time_14')"
                >
                <label for="time_14">14 дней</label>
              </div>
              <div class="options__item">
                <input class="options__input-radio" type="radio" id="time_30"
                       :checked="registrator.time === 'time_30'"
                       @change="changeRegistrator('time_30')"
                >
                <label for="time_30">30 дней</label>
              </div>
            </div>
          </div>
          <div class="params__price">
            <template v-if="registrator.cost > 0">
              ~ {{ registrator.cost }} тг
            </template>
            <template v-else>0 тг</template>
          </div>
        </div>
      </div>

      <div class="calc__wrap calc__wrap--second">
        <div class="options calc__options">
          <div class="options__title options__title--mb">
            Уровень сложности монтажа
            <i class="fas fa-info-circle options__title-icon"
               @click="showTooltip('montazh')"
            ></i>
            <div class="options__tooltip tooltip"
                 v-if="tooltip === 'montazh'"
            >
              <p>Стоимость установки видеонаблюдения напрямую зависит от условий, при которых будет осуществляться монтаж оборудования.</p>
              <p><strong>Простой</strong> - Работа в помещении, высота потолков до 3 м, монтаж кабеля осуществляется за фальшпотолком (армстронг), стены между помещениями из гипсокартона.</p>
              <p><strong>Средний</strong> - Высота потолков до 4 м, большая часть кабеля прокладывается в гофре или в кабель-канале, требуется сверление стен из бетона до 300 мм. </p>
              <p><strong>Сложный</strong> - работа на улице до -10&#176;C, высота потолков от 5-7 метров, сверление стен глубиной до 500 мм, стесненные условия работы в ночное время.</p>
              <i class="fas fa-times tooltip__close"
                 @click="closeTooltip()"
              ></i>
            </div>
          </div>
          <ul class="options__list">
            <li class="options__item">
              <input class="options__input-radio" type="radio" id="inst_1"
                     :checked="inst.type === 'inst_1'"
                     @change="changeInstallation('inst_1')"
              >
              <label for="inst_1">Простой</label>
            </li>
            <li class="options__item">
              <input class="options__input-radio" type="radio" id="inst_2"
                     :checked="inst.type === 'inst_2'"
                     @change="changeInstallation('inst_2')"
              >
              <label for="inst_2">Средний</label>
            </li>
            <li class="options__item">
              <input class="options__input-radio" type="radio" id="inst_3"
                     :checked="inst.type === 'inst_3'"
                     @change="changeInstallation('inst_3')"
              >
              <label for="inst_3">Сложный</label>
            </li>
            <li class="options__item">
              <input class="options__input-radio" type="radio" id="inst_0"
                     :checked="inst.type === 'inst_0'"
                     @change="changeInstallation('inst_0')"
              >
              <label for="inst_0">Без монтажа</label>
            </li>
          </ul>
        </div>
        <div class="calc__total">
          <span>Итого:</span><span>
            <template v-if="totalCost > 0">
              ~ {{ totalCost }} тг
            </template>
            <template v-else>0 тг</template>
        </span>
        </div>
      </div>

      <div class="calc__main-btn">
        <button class="button button--yellow showModal"
                data-micromodal-trigger="modal-calc"
        onclick="window.MicroModal.show('modal-calc');"
        >нужен подробный расчёт</button>
      </div>
    </div>
  </section>
</template>

<script>
import request from "../../request";

export default {
  name: "vue-Calc",
  data() {
    return {
      type: 'ahd',
      quality: 'fullhd',
      outside: {
        count: 4,
        focal: false,
        cost: 0,
      },
      inside: {
        count: 1,
        focal: false,
        cost: 0
      },
      registrator: {
        time: 'time_7',
        cost: 0
      },
      inst: {
        type: 'inst_1',
        cost: 0
      },
      totalCount: 0,
      totalCost: 0,
      priceData: {
        ahd: {
          fullhd: {
            price: 10900,
            focal: 7000,
            registrator: {
              'time_7': {
                5: 53000,
                9: 69300,
                17: 97000,
                1000: 281400
              },
              'time_14': {
                5: 53000,
                9: 69300,
                17: 108800,
                1000: 346900
              },
              'time_30': {
                5: 53000,
                9: 81100,
                17: 126800,
                1000: 446600
              },
            },
          },
          quadhd: {
            price: 17100,
            focal: 7000,
            registrator: {
              'time_7': {
                5: 79400,
                9: 112400,
                17: 192400,
                1000: 402600
              },
              'time_14': {
                5: 79400,
                9: 112400,
                17: 208800,
                1000: 470000
              },
              'time_30': {
                5: 91200,
                9: 140600,
                17: 276500,
                1000: 590000
              },
            },
          },
          ultrahd: {
            price: 34100,
            focal: 7000,
            registrator: {
              'time_7': {
                5: 186400,
                9: 199000,
                17: 402600,
                1000: 670000
              },
              'time_14': {
                5: 199000,
                9: 215000,
                17: 455200,
                1000: 790000
              },
              'time_30': {
                5: 215400,
                9: 268000,
                17: 560400,
                1000: 1030000
              },
            },
          },
        },
        ip: {
          fullhd: {
            price: 22000,
            focal: 12000,
            registrator: {
              'time_7': {
                5: 50800,
                9: 55200,
                17: 119600,
                33: 129600,
                1000: 505000
              },
              'time_14': {
                5: 50800,
                9: 67000,
                17: 187000,
                33: 197000,
                1000: 625000
              },
              'time_30': {
                5: 62600,
                9: 83400,
                17: 292000,
                33: 302000,
                1000: 815000
              },
            },
          },
          quadhd: {
            price: 35600,
            focal: 12000,
            registrator: {
              'time_7': {
                5: 55900,
                9: 67000,
                17: 124600,
                33: 204350,
                1000: 625000
              },
              'time_14': {
                5: 55900,
                9: 67000,
                17: 185000,
                33: 293950,
                1000: 745000
              },
              'time_30': {
                5: 67800,
                9: 120400,
                17: 294000,
                33: 473850,
                1000: 975000
              },
            },
          },
          ultrahd: {
            price: 74800,
            focal: 12000,
            registrator: {
              'time_7': {
                5: 93000,
                9: 109000,
                17: 167000,
                33: 329900,
                1000: 715000
              },
              'time_14': {
                5: 109000,
                9: 172000,
                17: 277000,
                33: 519700,
                1000: 1045000
              },
              'time_30': {
                5: 172000,
                9: 287000,
                17: 467000,
                33: 789000,
                1000: 1449000
              },
            },
          },
        },
        inst: {
          inst_0: 0,
          inst_1: 9000,
          inst_2: 14000,
          inst_3: 19000
        },
      },
      tooltip: null
    }
  },
  created() {
    this.get()
  },
  methods: {
    async get() {
      const response = await request.get('/api/v1/calc');
      if (response.data.success === true) {
        this.priceData = response.data.data
        this.calc()
      }
    },
    changeCount(item, action) {
      if (action === '+') {
        item.count += 1
      }
      if (action === '-') {
        if (item.count > 0) {
          item.count -= 1;
        }
      }
      if (parseInt(action) > 0) {
        item.count = parseInt(action)
      }
      this.calc()
    },
    changeTotalCount() {
      this.totalCount = this.outside.count + this.inside.count
    },
    changeType(value) {
      this.type = value;
      this.calc()
    },
    changeQuality(value) {
      this.quality = value;
      this.calc()
    },
    changeFocal(item, value) {
      item.focal = value
      this.calc()
    },
    changeInstallation(value) {
      this.inst.type = value
      this.calc()
    },
    changeRegistrator(value) {
      this.registrator.time = value
      this.calc()
    },
    calcInsideCost() {
      let price = parseInt(this.priceData[this.type][this.quality].price)
      if (this.inside.focal === true) {
        price += parseInt(this.priceData[this.type][this.quality].focal)
      }
      this.inside.cost = this.inside.count * price
    },
    calcOutsideCost() {
      let price = parseInt(this.priceData[this.type][this.quality].price)
      if (this.outside.focal === true) {
        price += parseInt(this.priceData[this.type][this.quality].focal)
      }
      this.outside.cost = this.outside.count * price
    },
    calcTotalCost() {
      this.totalCost = this.outside.cost + this.inside.cost + parseInt(this.registrator.cost) + parseInt(this.inst.cost)
    },
    calcInstallation() {
      this.inst.cost = parseInt(this.priceData.inst[this.inst.type] * this.totalCount)
    },
    calcRegistrator() {
      for (const max in this.priceData[this.type][this.quality].registrator[this.registrator.time]) {
        if (this.totalCount < parseInt(max)) {
          this.registrator.cost = parseInt(this.priceData[this.type][this.quality].registrator[this.registrator.time][max])
          break
        }
      }
    },
    calc() {
      this.changeTotalCount()
      this.calcInsideCost()
      this.calcOutsideCost()
      this.calcRegistrator()
      this.calcInstallation()
      this.calcTotalCost()
    },
    showTooltip(tooltip) {
      this.tooltip = tooltip
    },
    closeTooltip() {
      this.tooltip = null
    },
  },
}
</script>>