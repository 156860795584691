<template>
  <div class="modal__content auth" id="modal-auth-content">
    <template v-if="activeComponent === 'login'">
      <login
          @changeComponent="activeComponent = $event.component"
      />
    </template>

    <template v-if="activeComponent === 'register'">
      <register
          @changeComponent="activeComponent = $event.component"
      />
    </template>

    <template v-if="activeComponent === 'forgot'">
      <forgot
          @changeComponent="activeComponent = $event.component"
      />
    </template>
  </div>
</template>

<script>

import login from "./login";
import register from "./register";
import forgot from "./Forgot";

export default {
  name: "vie-Auth",
  components: {login, register, forgot},
  data() {
    return {
      activeComponent: 'login',
    }
  },
  methods: {

  },
}
</script>

<style scoped>

</style>