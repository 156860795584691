<template>
  <div>
    <div class="form__group">
      <label for="auth_login" class="form__label">Email</label>
      <input
          type="text"
          class="form__input"
          id="auth_login"
          placeholder="email@mail.ru"
          @change="validate"
          v-model="email"
      >
    </div>

    <div class="alert alert--error" role="alert"
         v-if="emailInvalidFeedBack !== null"
    >
      {{ emailInvalidFeedBack }}
    </div>

    <div class="alert alert--success" role="alert"
         v-if="emailValidFeedBack !== null"
    >
      {{ emailValidFeedBack }}
    </div>

    <button
        class="button button--blue auth__button"
        :disabled="disabled"
        @click.prevent="send"
    >
      <div class="lds-ring"
           v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      Восстановить пароль
    </button>

    <div class="d-flex" style="justify-content: space-between;">
      <a href="#" class="auth__link"
         @click.prevent="$emit('changeComponent', {component:'register'})"
      >Зарегистрироваться</a>
      <a href="#" class="auth__link"
         @click.prevent="$emit('changeComponent', {component:'login'})"
      >Назад</a>
    </div>
  </div>
</template>

<script>
import request from "../../request";

export default {
  name: "vue-Forgot",
  watch: {
    email() {
      this.validate()
      this.disabled = !(this.loginValid === true);
    },
  },
  data() {
    return {
      loginValid: null,
      email: '',
      emailInvalidFeedBack: null,
      emailValidFeedBack: null,
      disabled: true,
      loading: false,
    }
  },
  methods: {
    async send() {
      this.loading = true
      this.disabled = true
      this.emailInvalidFeedBack = null
      this.emailValidFeedBack = null

      const formdata = new FormData()
      formdata.append('email', this.email)
      const response = await request.post('/api/v1/auth/forgot', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false
        this.emailInvalidFeedBack = response.data.message
      }

      if (response.data.success === true) {
        this.loading = false
        this.disabled = false
        this.emailValidFeedBack = response.data.message
      }
    },
    validate() {
      const validatedEmail = this.validateEmail()
      if (validatedEmail !== null) {
        this.loginValid = true
      }
    },
    validateEmail() {
      return String(this.email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
  },
}
</script>

<style >

</style>