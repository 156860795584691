import Vue from 'vue'

import ModalLocationSelect from "./components/ModalLocationSelect/ModalLocationSelect"
import Auth from  "./components/Auth/Auth"
import Profile from "./components/Profile/Profile"
import Orders from "./components/Orders/Orders"
import Calc from "./components/Calc/Calc"


Vue.config.productionTip = false

const $modalLocationSelect = document.querySelector('#modal-location-select')
const $modalAuth = document.querySelector('#vue-modal-auth')
const $profile = document.querySelector('#vue-profile')
const $orders = document.querySelector('#vue-orders')
const $calc = document.querySelector('#vue-calc')

if ($modalLocationSelect) {
    new Vue({
        el: '#modal-location-select',
        data() {
            return {
                defaultCity: $modalLocationSelect.dataset.city
            }
        },
        template: '<ModalLocationSelect/>',
        components: {
            ModalLocationSelect
        }
    })
}

if ($modalAuth) {
    new Vue({
        el: '#vue-modal-auth',
        data() {
            return {}
        },
        template: '<Auth/>',
        components: {
          Auth
        }
    })
}

if ($profile) {
    new Vue({
        el: '#vue-profile',
        data() {
            return {
                user_id: $profile.dataset.id
            }
        },
        template: '<Profile/>',
        components: {
            Profile
        }
    })
}

if ($orders) {
    new Vue({
        el: '#vue-orders',
        data() {
            return {
                user_id: $orders.dataset.id
            }
        },
        template: '<Orders/>',
        components: {
            Orders
        }
    })
}

if ($calc) {
    new Vue({
        el: '#vue-calc',
        data() {
            return {

            }
        },
        template: '<Calc/>',
        components: {
            Calc
        }
    })
}